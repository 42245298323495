<template>
    <CModal
      centered
      :show="show"
      title="Confirm delete"
      @update:show="$emit('update:show', $event)"
      class="deleteCuratorModal"
      id="deleteCuratorModal"
    >
      <template #header-wrapper>
        <div class="deleteCuratorModal__header">
          <span>{{ title }}</span>
          <div @click="$emit('close')" class="deleteCuratorModal__header-close">×</div>
        </div>
      </template>
  
      <template #body-wrapper>
        <div class="deleteCuratorModal__body">
            <slot></slot>
            <div class="deleteCurataorModal__body__btnBlock">
                <CButton
                @click="$emit('confirm', true)"
                class="shadow-none deleteCuratorModal__btn"
                color="danger"
                >
                {{ $t('general.delete') }}
                </CButton>
                <CButton @click="$emit('close')" class="shadow-none deleteCuratorModal__btn" color="primary">
                {{ $t('general.cancel') }}
                </CButton>
            </div>
          </div>  
      </template>
  
      <template #footer-wrapper><span></span></template>
    </CModal>
  </template>
  
  <script>
  
  export default {
    name: 'confirmModal',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        required: true,
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .deleteCuratorModal {    
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 0;
      font-size: 21px;
      line-height: 1;
      font-weight: 500;
  
      @include media-breakpoint-up(md) {
        padding: 24px 32px 0;
      }
  
      &-close {
        font-size: 23px;
        font-weight: 100;
        cursor: pointer;
        user-select: none;
  
        &:hover {
          color: $danger;
        }
      }
    }
  
    &__btn {
      width: 100%;
      height: 44px;
    }
  
    &__body {
      @include media-breakpoint-up(md) {
        padding: 24px 32px 32px;
      }
    }
  }
  .deleteCurataorModal__body__btnBlock {
    margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 20px;
      }
  }

#deleteCuratorModal {
    /deep/ {
        .modal-dialog{
            width: 50%;
        }
    }
}
  </style>
  