export default function (PineJS) {
  const resolutionOptions = ['', '1', '5', '15', '30', '60', '120', '240', '720', '1D', '1W']
  return {
    name: 'MA 50+100+200',
    metainfo: {
      _metainfoVersion: 1,
      id: 'MA50_100_200@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      isCustomIndicator: true,
      transparency: 65,
      defaults: {
        styles: {
          ma50: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            visible: true,
            color: 'blue',
            transparency: 65
          },
          ma100: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            visible: true,
            color: 'white',
            transparency: 65
          },
          ma200: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            visible: true,
            color: 'yellow',
            transparency: 65
          },
        },
        precision: 0,
        palettes: {
        },
        inputs: {
          resolution: '',
          length50: 50,
          source50: 'close',
          offset50: 0,
          length100: 100,
          source100: 'close',
          offset100: 0,
          length200: 200,
          source200: 'close',
          offset200: 0,
        }
      },
      plots           : [
        {id: 'ma50', type: 'line'},
        {id: 'ma100', type: 'line'},
        {id: 'ma200', type: 'line'}
      ],
      styles          : {
        ma50: {
          title: 'MA50',
          histogramBase: 0
        },
        ma100: {
          title: 'MA100',
          histogramBase: 0
        },
        ma200: {
          title: 'MA200',
          histogramBase: 0
        },
      },
      description: 'MA 50+100+200',
      shortDescription: 'MA 50+100+200',
      is_price_study: true,
      palettes: {
      },
      inputs: [
        {
          id: 'resolution',
          name: 'resolution',
          type: 'resolution',
          defval: '',
          options: resolutionOptions,
          optionsTitles: {'': 'График', '1': '1m', '5': '5m', '15': '15m', '30': '30m', '60': '1h', '120': '2h', '240': '4h', '720': '12h', '1D': '1d', '1W': '1w'}
        },
        {id: 'length50', name: 'Length', defval: 50, min: 1, max: 2e3, type: 'integer'},
        {id: 'source50', name: 'Source', type: 'source', defval: 'close'},
        {id: 'offset50', name: 'Offset', defval: 0, min: -500, max: 500, type: 'integer'},
        {id: 'length100', name: 'Length', defval: 100, min: 1, max: 2e3, type: 'integer'},
        {id: 'source100', name: 'Source', type: 'source', defval: 'close'},
        {id: 'offset100', name: 'Offset', defval: 0, min: -500, max: 500, type: 'integer'},
        {id: 'length200', name: 'Length', defval: 200, min: 1, max: 2e3, type: 'integer'},
        {id: 'source200', name: 'Source', type: 'source', defval: 'close'},
        {id: 'offset200', name: 'Offset', defval: 0, min: -500, max: 500, type: 'integer'},
      ]
    },
    constructor: function () {
      let symIndex = -1, resolution = '';
      const resolutionsMap = {
        '': ['', '1', '5', '15', '30', '60', '120', '240', '720', '1D', '1W'],
        '1': ['', '1', '5', '15', '30', '60', '120', '240', '720', '1D', '1W'],
        '5': ['', '5', '15', '30', '60', '120', '240', '720', '1D', '1W'],
        '15': ['', '15', '30', '60', '120', '240', '720', '1D', '1W'],
        '30': ['', '30', '60', '120', '240', '720', '1D', '1W'],
        '60': ['', '60', '120', '240', '720', '1D', '1W'],
        '120': ['', '120', '240', '720', '1D', '1W'],
        '240': ['', '240', '720', '1D', '1W'],
        '720': ['', '720', '1D', '1W'],
        '1D': ['', '1D', '1W'],
        '1W': ['', '1W']
      }
      this.init = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        resolution = this._input(0)
        if(resolution){
          if(resolutionsMap[PineJS.Std.period(this._context)].indexOf(resolution) < 0){
            resolution = ''
          }
          if(resolution){
            symIndex = (this._context.symbol.script.symbols || this._context.symbol.script._symbols).findIndex((e) => (e.ticker || '' === this._context.symbol.ticker) && (e.period === resolution))
            if(symIndex < 0){
              this._context.new_sym(this._context.symbol.ticker, resolution)
              symIndex = (this._context.symbol.script.symbols || this._context.symbol.script._symbols).findIndex((e) => (e.ticker || '' === this._context.symbol.ticker) && (e.period === resolution))
            }
          }
        }
      };
      this.main = function (e, t) {
        this._context = e
        this._input = t
        if(symIndex > 0 && resolution){
          this._context.select_sym(symIndex);
        }
        const vals = {},
              src50 = this._input(2),
              src100 = this._input(5),
              src200 = this._input(8),
              val50 = vals[src50] = PineJS.Std[src50](this._context),
              val100 = vals[src100] !== undefined ? vals[src100] : (vals[src100] = PineJS.Std[src100](this._context)),
              val200 = vals[src200] !== undefined ? vals[src200] : (vals[src200] = PineJS.Std[src200](this._context))
        return [
          {
            value: PineJS.Std.sma(this._context.new_var(val50), this._input(1), this._context),
            offset: +this._input(3)
          },
          {
            value: PineJS.Std.sma(this._context.new_var(val100), this._input(4), this._context),
            offset: +this._input(6)
          },
          {
            value: PineJS.Std.sma(this._context.new_var(val200), this._input(7), this._context),
            offset: +this._input(9)
          },
        ]
      }
    }
  }
}
