import moment from 'moment';
import store from '@/store';

const studyName = 'Accum Delta'

export default function (PineJS, tvWidget) {
  return {
    name: studyName,
    metainfo: {
      _metainfoVersion: 1,
      id: 'AccumDelta@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      transparency: 65,
      defaults: {
        styles: {
          accum_delta: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            visible: true,
            color: '#000080',
            transparency: 65
          }
        },
        precision: 0,
        palettes: {
          accumDeltaPalette: {
            colors: {
              0: {color: '#eb4d5c', width: 1, style: 0},
              1: {color: '#53b987', width: 1, style: 0}
            }
          }
        },
        inputs: {
          fromDate: '',
          endDate: ''
        }
      },
      plots           : [
        {id: 'accum_delta', type: 'line'},
        {id: 'accumDeltaPalette', palette: 'accumDeltaPalette', target: 'accum_delta', type: 'colorer'},
      ],
      styles          : {
        accum_delta: {
          title: 'Accum Delta',
          histogramBase: 0
        }
      },
      description: 'Accum Delta',
      shortDescription: 'Accum Delta',
      is_price_study: false,
      palettes: {
        accumDeltaPalette: {
          colors: {
            0: {name: '< 0'},
            1: {name: '> 0'}
          }
        }
      },
      inputs: [
        {id: 'fromDate', name: 'From date(DD.MM.YYYY)', defval: '', type: 'text'},
        {id: 'endDate', name: 'End date(DD.MM.YYYY)', defval: '', type: 'text'}
      ]
    },
    constructor: function () {
      let studyId, study, lastFromDate = '', lastEndDate = '', ts = 0, tsEnd = 0

      this.init = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const activeChart = tvWidget.activeChart()
        studyId = activeChart.getAllStudies().find(({name}) => name === studyName)?.id
        if(studyId){
          study = activeChart.getStudyById(studyId)
        }
      };

      this.main = function (context, inputCallback) {
        this._input = inputCallback
        this._context = context

        const fromDate = this._input(0)
        if(lastFromDate !== fromDate){
          lastFromDate = fromDate
          if(lastFromDate){
            if(isNaN(lastFromDate)){
              lastFromDate = lastFromDate.replace(/(\d{1,2})\.(\d{1,2}).(\d{4})/, '$3-$2-$1')
            }else{
              lastFromDate = 1*lastFromDate;
              if(lastFromDate < 10000000000){
                lastFromDate *= 1000;
              }
            }
            let parseData = moment(lastFromDate).utc(true)
            if(parseData.isValid() && parseData.isBefore()){
              lastFromDate = parseData.format('YYYY-MM-DD HH:mm')
              study?.setInputValues([{'id': 'fromDate', 'value': lastFromDate}])
              ts = parseData.valueOf()
            }else{
              lastFromDate = ''
              ts = 0;
              study?.setInputValues([{'id': 'fromDate', 'value': lastFromDate}])
            }
          }else{
            ts = 0
          }
        }

        const endDate = this._input(1)
        if(lastEndDate !== endDate){
          lastEndDate = endDate
          if(lastEndDate){
            if(isNaN(lastEndDate)){
              lastEndDate = lastEndDate.replace(/(\d{1,2})\.(\d{1,2}).(\d{4})/, '$3-$2-$1')
            }else{
              lastEndDate = 1*lastEndDate;
              if(lastEndDate < 10000000000){
                lastEndDate *= 1000;
              }
            }
            let parseData = moment(lastEndDate).utc(true)
            if(parseData.isValid() && parseData.isBefore()){
              lastEndDate = parseData.format('YYYY-MM-DD HH:mm')
              study?.setInputValues([{'id': 'endDate', 'value': lastEndDate}])
              tsEnd = parseData.valueOf()
            }else{
              lastEndDate = ''
              tsEnd = 0;
              study?.setInputValues([{'id': 'endDate', 'value': lastEndDate}])
            }
          }else{
            tsEnd = 0
          }
        }
        const value = this._context.symbol.time < ts || (tsEnd && this._context.symbol.time > tsEnd) ? 0 : PineJS.Std.cum(
          ((store.state.deltaMap[(this._context.symbol.info.ticker || this._context.symbol.ticker) + '_' + this._context.symbol.period] || {})[this._context.symbol.time] || [0])[0] || 0,
          this._context
        )
        return [
          value,
          value < 0 ? 0 : 1
        ]
      }
    }
  }
}
