import i18n from "@/i18n"
import axios from 'axios';

export default class PropsDropdown {

  /**
   * @param {IChartingLibraryWidget} widget
   * @param {Object} callbacks
   */
  constructor (widget, callbacks = {}) {
    this.showConfirmRemoveSignalsPlotModal = false
    this.callbacks = callbacks
    this.widget = widget
    this.widget.headerReady().then(() => {
      this.widget.createDropdown({
        title: '',
        tooltip: i18n.t('tvPropsDropdown.tooltip').toString(),
        items: this.getItems(),
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="22px" height="22px"><path fill="currentColor" d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>`,
        align: 'right'
      }).then((myDropdownApi) => {
        this.dropdownApi = myDropdownApi
      });
    })
    window.addEventListener('storage', (e, a) => {
      switch(e.key){
        case 'tvPropHideNotifications':
          this.dropdownApi.applyOptions({
            items: this.getItems()
          })
          if(this.callbacks.hideNotifications){
            this.callbacks.hideNotifications(this.hideNotifications)
          }
          break;
        case 'tvPropHideSignalsPlot':
          this.dropdownApi.applyOptions({
            items: this.getItems()
          })
          if(this.callbacks.hideSignalsPlot){
            this.callbacks.hideSignalsPlot(this.hideSignalsPlot)
          }
          break;
        case 'tvPropRemovedSignalsPlot':
          if(!e.newValue){
            break;
          }
          try {
            const data = JSON.parse(e.newValue)
            if(data.pair && data.removed && this.callbacks.removeSignalsPlot){
              this.callbacks.removeSignalsPlot(data.pair, data.removed)
            }
          } catch(e) {
            console.error(e)
          }
          break;
      }
    });
  }

  get hideNotifications(){
    const prop = localStorage.getItem('tvPropHideNotifications')
    return (prop && prop !== 'false')
  }
  set hideNotifications(value){
    localStorage.setItem('tvPropHideNotifications', value)
  }

  get hideSignalsPlot(){
    const prop = localStorage.getItem('tvPropHideSignalsPlot')
    return (prop && prop !== 'false')
  }
  set hideSignalsPlot(value){
    localStorage.setItem('tvPropHideSignalsPlot', value)
  }
  getItems(){
    return [
      {
        title:  i18n.t('tvPropsDropdown.' + (this.hideNotifications ? 'showNotifications' : 'hideNotifications')).toString(),
        onSelect: () => {
          this.hideNotifications = !this.hideNotifications
          this.dropdownApi.applyOptions({
            items: this.getItems()
          })
          if(this.callbacks.hideNotifications){
            this.callbacks.hideNotifications(this.hideNotifications)
          }
        },
      },
      {
        title: i18n.t('tvPropsDropdown.' + (this.hideSignalsPlot ? 'showSignalsPlot' : 'hideSignalsPlot')).toString(),
        onSelect: () => {
          this.hideSignalsPlot = !this.hideSignalsPlot
          this.dropdownApi.applyOptions({
            items: this.getItems()
          })
          if(this.callbacks.hideSignalsPlot){
            this.callbacks.hideSignalsPlot(this.hideSignalsPlot)
          }
        },
      },
      {
        title: i18n.t('tvPropsDropdown.removeSignalsPlot').toString(),
        onSelect: async () => {
          this.showConfirmRemoveSignalsPlotModal = true
        },
      }
    ]
  }

  async removeSignalsPlotCallback () {
    const symbolExt = this.widget.activeChart().symbolExt()
    let pair = symbolExt.symbol.toUpperCase()
    if(!pair.includes(':') && symbolExt.exchange){
      pair = symbolExt.exchange.toUpperCase() + ':' + pair
    }
    const result = await axios.delete('api/v1/tv/signals_plot/' + pair).finally(() => this.showConfirmRemoveSignalsPlotModal = false)
    if(result?.data?.success && result?.data?.removed && this.callbacks.removeSignalsPlot){
      const data = {
        pair: pair,
        removed: result.data.removed
      }
      localStorage.setItem('tvPropRemovedSignalsPlot', JSON.stringify(data))
      this.callbacks.removeSignalsPlot(pair, result.data.removed)
    }
  }
}
