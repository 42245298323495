import store from "@/store";
import {SCREENER_PAGE_FUTURES} from "@/shared/constants";

export default function (PineJS) {
  const studyName = 'CIB (Correlation Index Bitcoin)'
  const isFutures = store.getters.getLastScreen === SCREENER_PAGE_FUTURES
  return {
    name: studyName,
    metainfo: {
      _metainfoVersion: 52,
      id: 'cib@tv-basicstudies-1',
      description: studyName,
      shortDescription: 'CIB',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      usePlotsZOrder: true,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
            visible: true,
            color: '#ffffff'
          },
          plot_baseline: {
            linewidth: 0,
            linestyle: 0,
            transparency: 0,
            trackPrice: false,
            display: 1,
            visible: false,
            color: '#ffffff'
          },
        },
        bands: [
          {
            color    : '#4daf51',
            transparency: 0,
            linestyle: 2,
            linewidth: 2,
            visible: true,
            value: 0.7,
            zorder: -1.1,
          }, {
            color: '#918836',
            transparency: 0,
            linestyle: 2,
            linewidth: 1,
            visible: true,
            value: 0,
            zorder: -1.11
          }, {
            color: '#f33644',
            transparency: 0,
            linestyle: 2,
            linewidth: 2,
            visible: true,
            value: -0.7,
            zorder: -1.111
          },
        ],
        palettes: {
          upperPalette: {
            colors: [
              {
                color: '#4caf50',
              },
              {
                color: 'rgba(76,175,80,0)',
              },
            ],
          },
          lowerPalette: {
            colors: [
              {
                color: '#f23645',
              },
              {
                color: 'rgba(242,54,69,0)',
              },
            ],
          },
        },
        filledAreasStyle: {
          fill_upper: {
            color: '#4caf50',
            transparency: 50,
            visible: true,
          },
          fill_lower: {
            color: '#f23645',
            transparency: 50,
            visible: true,
          },
        },
        inputs: {in_0: (isFutures ? 'BINANCE_PERP' : 'BINANCE')  + ':BTCUSDT', in_1: 20}
      },
      plots: [
        {id: "plot_0", type: "line", },
        {
          id: 'plot_baseline',
          type: 'line',
        },
        {
          id: 'plot_upper_fill',
          type: 'colorer',
          target: 'fill_upper',
          palette: 'upperPalette',
        },
        {
          id: 'plot_lower_fill',
          type: 'colorer',
          target: 'fill_lower',
          palette: 'lowerPalette',
        },
      ],
      styles: {
        plot_0: {
          title: 'Plot',
          histogramBase: 0,
          joinPoints: false,
          zorder: 1.1
        },
        plot_baseline: {
          title: 'Baseline',
          isHidden: true,
          zorder: 1,
        },
      },
      bands: [
        {id: 'hline_0', name: 'UpperLimit', zorder: -1.1},
        {id: 'hline_1', name: 'Base Line', zorder: -1.11},
        {id: 'hline_1', name: 'LowerLimit', zorder: -1.111},
      ],
      filledAreas: [
        {
          id: 'fill_upper',
          objAId: 'plot_0',
          objBId: 'plot_baseline',
          type: 'plot_plot',
          title: 'Above baseline',
          palette: 'upperPalette',
          fillToIntersection: true,
          zorder: -2,
        },
        {
          id: 'fill_lower',
          objAId: 'plot_0',
          objBId: 'plot_baseline',
          type: 'plot_plot',
          title: 'Below baseline',
          palette: 'lowerPalette',
          fillToIntersection: true,
          zorder: -2.1,
        },
      ],
      palettes: {
        upperPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: [
            {
              name: 'Upper color',
            },
          ],
        },
        lowerPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: [
            {
              name: 'Lower color',
            },
          ],
        },
      },
      inputs: [
        {
          id: "in_0",
          name: "symbol",
          defval: (isFutures ? 'BINANCE_PERP' : 'BINANCE')  + ':BTCUSDT',
          type: "symbol",
        }, {
          id: "in_1",
          name: "length",
          defval: 20,
          type: "integer",
          min: 1,
          max: 2e3
        }
      ],
      format: {precision: 2, type: "price"},
    },
    constructor: function () {
      this.init = function (e, t) {
        this._context = e
        this._input = t
        this._context.new_sym(this._input(0), PineJS.Std.period(this._context))
      }
      this.main = function (e, t) {
        this._context = e
        this._input = t;
        const i = this._context.new_unlimited_var(this._context.symbol.time),
          s = PineJS.Std.close(this._context)

        this._context.select_sym(1)
        const o = this._context.new_unlimited_var(this._context.symbol.time),
          a = PineJS.Std.close(this._context),
          l = this._context.new_unlimited_var(a);

        this._context.select_sym(0)
        const c = l.adopt(o, i, 0),
          h = this._context.new_var(s),
          d = this._context.new_var(c)
        const val = PineJS.Std.correlation(h, d, this._input(1), this._context)
        const upperColor = val >= 0 ? 0 : 1
        const lowerColor = val < 0 ? 0 : 1;
        return [val, 0, upperColor, lowerColor]
      }
    }
  }
}
