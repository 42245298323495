import axios from 'axios'
import store from '@/store';
const clientId = 'candles'
const tvStorageUrl = axios.defaults.baseURL + '/api/v1/tv_save_load/charts'
const tvStorageDrawingTemplatesUrl = axios.defaults.baseURL + '/api/v1/tv_save_load/drawing_templates'
const tvStorageStudyTemplatesUrl = axios.defaults.baseURL + '/api/v1/tv_save_load/study_templates'

const guestCharts = []

export function getAllChartsAutosave (params = null) {
  if(store.getters['user/isGuest']){
    return Promise.resolve(guestCharts)
  }
  if(params === null){
    params = {}
  }
  if(!params.client_id){
    params.client_id = clientId
  }
  return axios.get(tvStorageUrl, {params: params}).then(res => res.data.data)
}

export function removeChartAutosave (chartId) {
  if(store.getters['user/isGuest']){
    for (let i = 0; i < guestCharts.length; ++i) {
      if (guestCharts[i].id === chartId) {
        guestCharts.splice(i, 1);
        return Promise.resolve();
      }
    }
    return Promise.reject();
  }

  return axios.delete(tvStorageUrl, {
    params: {chart: chartId}
  })
}

export function saveChartAutosave (chartData, params = null)  {
  if(store.getters['user/isGuest']){
    if (!chartData.id) {
      chartData.id = Math.random().toString()
    } else {
      removeChartAutosave(chartData.id)
    }
    chartData.timestamp = new Date().valueOf()
    guestCharts.push(chartData)
    return Promise.resolve(chartData.id)
  }

  if(params === null){
    params = {}
  }
  if(!params.client_id){
    params.client_id = clientId
  }
  chartData.theme = store.getters.theme
  return axios.post(tvStorageUrl, chartData, {params: params}).then(res => res.data.id)
}

let getChartContentLoading = false
export function getChartContentAutosave (chartId, params = null) {
  if(store.getters['user/isGuest']){
    for (let i = 0; i < guestCharts.length; ++i) {
      if (guestCharts[i].id === chartId) {
        return Promise.resolve(guestCharts[i].content)
      }
    }
    return Promise.reject();
  }

  if (getChartContentLoading === true) {
    console.warn('Предыдущий запрос "getChartContent" ещё не выполнен')
    return Promise.reject()
  }

  if(params === null){
    params = {}
  }
  if(!params.client_id){
    params.client_id = clientId
  }

  getChartContentLoading = true
  return axios.get(tvStorageUrl, {
    params: {
      chart_id: chartId,
      content: 1,
      ...params
    }
  })
    .then(res => res.data.data)
    .finally(() => getChartContentLoading = false)
}


let getStudyTemplateContentLoading = false
export function getAllStudyTemplates (params = null) {
  if(params === null){
    params = {}
  }
  if(!params.client_id){
    params.client_id = clientId
  }
  return axios.get(tvStorageStudyTemplatesUrl, {params: params}).then(res => res.data?.data)
}

export function saveStudyTemplate (data, params = null)  {
  data.theme = store.getters.theme
  data.client_id = params && params.client_id || clientId
  return axios.post(tvStorageStudyTemplatesUrl, data).then(res => res.data?.data)
}

export function getStudyTemplateContent (data, params = null) {
  if (getStudyTemplateContentLoading === true) {
    console.warn('Предыдущий запрос "getStudyTemplateContent" ещё не выполнен')
    return Promise.reject()
  }

  data.client_id = params && params.client_id || clientId

  getStudyTemplateContentLoading = true
  return axios.get(tvStorageStudyTemplatesUrl, {params: data})
    .then(res => res.data?.data?.content)
    .finally(() => getStudyTemplateContentLoading = false)
}

export function removeStudyTemplate (data, params = null) {
  data.client_id = params && params.client_id || clientId
  return axios.delete(tvStorageStudyTemplatesUrl, {
    params: data
  })
}

let getDrawingTemplateContentLoading = false
export function getDrawingTemplates (toolName) {
  return axios.get(tvStorageDrawingTemplatesUrl, {params: {
      client_id: clientId,
      tool: toolName
    }}).then(res => res.data?.data)
}

export function saveDrawingTemplate (toolName, templateName, content)  {
  return axios.post(tvStorageDrawingTemplatesUrl, {
    theme: store.getters.theme,
    client_id: clientId,
    tool: toolName,
    name: templateName,
    content: content
  }).then(res => res.data?.data)
}

export function loadDrawingTemplate (toolName, templateName) {
  if (getDrawingTemplateContentLoading === true) {
    console.warn('Предыдущий запрос "loadDrawingTemplate" ещё не выполнен')
    return Promise.reject()
  }

  getDrawingTemplateContentLoading = true
  return axios.get(tvStorageDrawingTemplatesUrl, {params: {
      client_id: clientId,
      tool: toolName,
      name: templateName
    }})
    .then(res => res.data?.data?.content)
    .finally(() => getDrawingTemplateContentLoading = false)
}

export function removeDrawingTemplate (params) {
  params.client_id = clientId
  return axios.delete(tvStorageDrawingTemplatesUrl, {
    params: params
  })
}
