const studyName = 'Volatility'

export default function (PineJS, tvWidget) {
  return {
    name: studyName,
    metainfo: {
      _metainfoVersion: 52,
      name: studyName,
      scriptIdPart: '',
      id: 'Volatility@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      transparency: 0,
      defaults: {
        styles: {
          Volatility: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            visible: true,
            color: '#f7525f',
            transparency: 0
          }
        },
        palettes: {
        },
        inputs: {
          source: 'close',
          periods: 4
        }
      },
      plots           : [
        {id: 'Volatility', type: 'line'}
      ],
      styles          : {
        Volatility: {
          title: 'Volatility'
        },
      },
      description: studyName,
      shortDescription: studyName,
      palettes: {
      },
      inputs: [
        {id: 'source', name: 'Source', type: 'source', defval: 'close'},
        {id: 'periods', name: 'Periods', type: 'integer', defval: 4, min: 1}
      ],
      format: {}
    },
    constructor: function () {
      let studyId, study, lastPeriod


      this.stdev = function (e, t, i) {
        return PineJS.Std.sqrt(this.variance(e, t, i))
      }
      this.variance = function (e, t, i) {
        return this.variance2(e, PineJS.Std.sma(e, t, i), t)
      }
      this.variance2 = function (e, t, i) {
        let s, r, n = 0;
        for(s = 0; s < i; s++){
          n += (r = e.get(s) - t) * r
        }
        return n / (i - 1);
      }

      this.init = function (e, t) {
        this._context = e
        this._input = t
        this.source = this._input(0)
        this.periods = this._input(1)
        if(this._context.symbol.period !== lastPeriod){
          lastPeriod = this._context.symbol.period
          let newPeriods = ['1', '5'].indexOf(this._context.symbol.period) !== -1 ? 6 : 4
          if(newPeriods !== this.periods){
            this.periods = newPeriods
            const activeChart = tvWidget.activeChart()
            studyId = activeChart.getAllStudies().find(({name}) => name === studyName)?.id
            if(studyId){
              study = activeChart.getStudyById(studyId)
            }
            study?.setInputValues([{'id': 'periods', 'value': this.periods}])
          }
        }
      }

      this.main = function (e, t) {
        this._context = e
        this._input = t
        const v = this._context.new_var(PineJS.Std[this.source](this._context))
        return [
          this.stdev(this._context.new_var(PineJS.Std.log(v.get() / v.get(1))), this.periods, this._context) * 100
        ]
      }
    }
  }
}
