import store from '@/store';

const studyName = 'Raketa'

export default function (PineJS, dataStore) {
  return {
    name: studyName,
    metainfo: {
      _metainfoVersion: 52,
      id: studyName.replaceAll(/[[\W]/g, '_') + '@tv-basicstudies-1',
      name: studyName,
      description: studyName,
      shortDescription: studyName,
      scriptIdPart: '',
      sTVScript: false,
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      is_price_study: false,
      usePlotsZOrder: true,
      transparency: 0,
      defaults: {
        styles: {
          plot_0: {
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
            visible: true,
            color: '#7E57C2'
          },
        },
        bands: [
          {
            color    : '#4caf50',
            transparency: 50,
            linestyle: 0,
            linewidth: 1,
            visible: true,
            value: 100,
            zorder: -1.1,
          }, {
            color: '#ffeb3b',
            transparency: 50,
            linestyle: 0,
            linewidth: 1,
            visible: true,
            value: 34,
            zorder: -1.11
          }, {
            color: '#ffeb3b',
            transparency: 50,
            linestyle: 0,
            linewidth: 1,
            visible: true,
            value: -34,
            zorder: -1.111
          }, {
            color: '#f23645',
            transparency: 50,
            linestyle: 0,
            linewidth: 1,
            visible: true,
            value: -100,
            zorder: -1.1111
          }
        ],
        filledAreasStyle: {
          fill_0: {
            color: '#4caf50',
            transparency: 85,
            visible: true
          },
          fill_1: {
            color: '#ffeb3b',
            transparency: 85,
            visible: true
          },
          fill_2: {
            color: '#f23645',
            transparency: 85,
            visible: true
          }
        },
      },
      plots: [
        {id: 'plot_0', type: 'line'},
      ],
      styles: {
        plot_0: {
          title: 'Plot',
          histogramBase: 0,
          joinPoints: false,
          zorder: 1
        },
      },
      bands: [
        {id: 'hline_0', name: 'Top Limit', zorder: -1.1},
        {id: 'hline_1', name: 'UpperLimit', zorder: -1.11},
        {id: 'hline_2', name: 'LowerLimit', zorder: -1.111},
        {id: 'hline_3', name: 'Bottom Limit', zorder: -1.1111}
      ],
      filledAreas     : [
        {id: 'fill_0', objAId: 'hline_0', objBId: 'hline_1', type: 'hline_hline', title: 'Hlines Background', zorder: -2},
        {id: 'fill_1', objAId: 'hline_1', objBId: 'hline_2', type: 'hline_hline', title: 'Mlines Background', zorder: -2.1},
        {id: 'fill_2', objAId: 'hline_2', objBId: 'hline_3', type: 'hline_hline', title: 'Llines Background', zorder: -2.11}
      ],
      palettes: {
      },
      inputs: [],
      format: {precision: 2, type: 'price'}
    },
    constructor: function () {
      this.main = function (e, t) {
        this._context = e
        this._input = t
        return [store.state.raketa[this._context.symbol.time] || 0]
      }
    }
  }
}
