const studyName = 'Trends'

export default function (PineJS, dataStore) {
  return {
    name: studyName,
    metainfo: {
      _metainfoVersion: 52,
      name: studyName,
      scriptIdPart: '',
      sTVScript: false,
      id: studyName.replaceAll(/[[\W]/g, '_') + '@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_price_study: true,
      is_hidden_study: false,
      isCustomIndicator: true,
      transparency: 0,
      defaults: {
        styles: {
          plot_0: {
            plottype: 'shape_triangle_up',
            visible: true,
            location: 'AboveBar',
            transparency: 0,
            color: '#4caf50',
            size: 20,
            width: 20
          },
          plot_1: {
            plottype: 'shape_triangle_down',
            visible: true,
            location: 'BelowBar',
            transparency: 0,
            color: '#F23645'
          },
          plot_2: {
            plottype: 'shape_label_up',
            visible: true,
            location: 'BelowBar',
            transparency: 0,
            color: '#ffeb3b'
          },
          plot_3: {
            plottype: 'shape_label_down',
            visible: true,
            location: 'AboveBar',
            transparency: 0,
            color: '#ffeb3b'
          }
        }
      },
      plots: [
        {id: 'plot_0', type: 'shapes'},
        {id: 'plot_1', type: 'shapes'},
        {id: 'plot_2', type: 'shapes'},
        {id: 'plot_3', type: 'shapes'}
      ],
      styles: {
        plot_0: {
          title: 'Up only',
          isHidden: false
        },
        plot_1: {
          title: 'Down only',
          isHidden: false
        },
        plot_2: {
          title: 'Perfectly fit long',
          isHidden: false
        },
        plot_3: {
          title: 'Perfectly fit short',
          isHidden: false
        }
      },
      description: studyName,
      shortDescription: studyName,
      palettes: {
      },
      inputs: [],
      format: {type: 'inherit'}
    },
    constructor: function () {
      this.init = function (e, t) {
        this._context = e
        this._input = t
        this.symbolKey = (this._context.symbol.info.ticker || this._context.symbol.ticker) + '_' + this._context.symbol.period
        if(!this.symbolKey.includes(':')){
          this.symbolKey = 'BINANCE:' + this.symbolKey
        }
      }

      this.main = function (e, t) {
        this._context = e
        this._input = t
        if(!dataStore[this.symbolKey]){
          return [null, null, null, null]
        }
        const ts = this._context.symbol.time / 1000 | 0
        return [
          dataStore[this.symbolKey][0].includes(ts) ? PineJS.Std.high(this._context) : null,
          dataStore[this.symbolKey][1].includes(ts) ? PineJS.Std.low(this._context) : null,
          dataStore[this.symbolKey][2].includes(ts) ? PineJS.Std.low(this._context) : null,
          dataStore[this.symbolKey][3].includes(ts) ? PineJS.Std.high(this._context) : null
        ]
      }
    }
  }
}
