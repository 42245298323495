export default function (PineJS) {
  return {
    name: 'NATR',
    metainfo: {
      _metainfoVersion: 1,
      id: 'Natr@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      transparency: 0,
      defaults: {
        styles: {
          natr: {
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            visible: true,
            color: '#ff9800',
            transparency: 0
          }
        },
        precision: 2,
        palettes: {
        },
        inputs: {
          source: 'close',
          length: 14
        }
      },
      plots           : [
        {id: 'natr', type: 'line'}
      ],
      styles          : {
        natr: {
          title: 'NATR',
          histogramBase: 0
        },
      },
      description: 'NATR',
      shortDescription: 'NATR',
      is_price_study: false,
      palettes: {
      },
      inputs: [
        {id: 'source', name: 'Source', type: 'source', defval: 'close'},
        {id: 'length', name: 'Length', type: 'integer', defval: 14, min: 1}
      ]
    },
    constructor: function () {
      this.main = function (e, t) {
        this._context = e
        this._input = t
        return [
          100 * PineJS.Std.atr(this._input(1), this._context) / PineJS.Std[this._input(0)](this._context)
        ]
      }
    }
  }
}
