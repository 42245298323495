import store from '@/store'
export default function (PineJS) {
  return {
    name: 'Delta',
    metainfo: {
      _metainfoVersion: 1,
      id: 'Delta@tv-basicstudies-1',
      isTVScript: false,
      isTVScriptStub: false,
      is_hidden_study: false,
      transparency: 65,
      defaults: {
        styles: {
          delta: {
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            visible: true,
            color: '#000080',
            transparency: 65
          },
          delta_ma: {
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            visible: false,
            color: '#ffeb3b',
            transparency: 20
          }
        },
        precision: 0,
        palettes: {
          deltaPalette: {
            colors: {
              0: {color: '#eb4d5c', width: 1, style: 0},
              1: {color: '#53b987', width: 1, style: 0}
            }
          }
        },
        inputs: {
          numberAbs: false,
          deltaMaLength: 21
        }
      },
      plots           : [
        {id: 'delta', type: 'line'},
        {id: 'deltaPalette', palette: 'deltaPalette', target: 'delta', type: 'colorer'},
        {id: 'delta_ma', type: 'line'}
      ],
      styles          : {
        delta: {
          title: 'Delta',
          histogramBase: 0
        },
        delta_ma: {
          title: 'Delta MA',
          histogramBase: 0
        }
      },
      description: 'Delta',
      shortDescription: 'Delta',
      is_price_study: false,
      palettes: {
        deltaPalette: {
          colors: {
            0: {name: '< 0'},
            1: {name: '> 0'}
          }
        }
      },
      inputs: [
        {id: 'numberAbs', name: 'По модулю', defval: true, type: 'bool'},
        {id: 'deltaMaLength', name: 'MA Length', defval: 21, type: 'integer', min: 1, max: 2e3},
      ]
    },
    constructor: function () {
      this.main = function (e, t) {
        this._context = e
        this._input = t
        const delta = ((store.state.deltaMap[(this._context.symbol.info.ticker || this._context.symbol.ticker) + '_' + this._context.symbol.period] || {})[this._context.symbol.time] || [0])[0] || 0
        const isNegative = delta < 0
        const value = this._input(0) && isNegative ? -delta : delta
        return [
          value,
          isNegative ? 0 : 1,
          PineJS.Std.sma(this._context.new_var(value), this._input(1), this._context)
        ]
      }
    }
  }
}
