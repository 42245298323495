var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    staticClass: "deleteCuratorModal",
    attrs: {
      centered: "",
      show: _vm.show,
      title: "Confirm delete",
      id: "deleteCuratorModal",
    },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "header-wrapper",
          fn: function () {
            return [
              _c("div", { staticClass: "deleteCuratorModal__header" }, [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "div",
                  {
                    staticClass: "deleteCuratorModal__header-close",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("×")]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "body-wrapper",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "deleteCuratorModal__body" },
                [
                  _vm._t("default"),
                  _c(
                    "div",
                    { staticClass: "deleteCurataorModal__body__btnBlock" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "shadow-none deleteCuratorModal__btn",
                          attrs: { color: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("confirm", true)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.delete")) + " ")]
                      ),
                      _c(
                        "CButton",
                        {
                          staticClass: "shadow-none deleteCuratorModal__btn",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }